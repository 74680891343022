import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from "@/utilities/commonFunctions";

@Component({
  
})

export default class EventsPrint extends Vue {

  
  eventMode='View'
   
  @Prop()
  editEventPayload!:{}

  private objScreenText: ScreenText = new ScreenText();
 
  fileArray:any=[]
  public plannerEventObj: any = {
    id: 0,
    programId: 0,
    siteId: 0,
    name: "",
    location: "",
    date: "",
    startDate: "",
    endDate: "",
    startTime: '7:00 AM',
    endTime: "7:30 AM",
    notes: "",
    fileIds:[],
    isEvent:true
  };
  @Prop()
  details!: any

  @Watch('editEventPayload',{deep:true,immediate:true})
  eventPayload(val: any) {
    this.eventToEdit(val);
  }
  eventToEdit(val: any){
    if(val && Object.keys(val).length>0 ){
      this.plannerEventObj.name=val.name
      this.plannerEventObj.id=val.id
      this.plannerEventObj.location=val.location
      this.plannerEventObj.endTime=val.event_end_time
      this.plannerEventObj.startTime=val.event_start_time
      this.plannerEventObj.notes=val.notes
      this.plannerEventObj.date=APP_UTILITIES.formatDate(val.startDate,false,false)
      this.plannerEventObj.isEvent = val.isEvent;
      this.plannerEventObj.startDate = APP_UTILITIES.formatDate(val.startDate,false,false);
      this.plannerEventObj.endDate = APP_UTILITIES.formatDate(val.endDate,false,false);
      this.plannerEventObj.fileIds =  val.documentDtos? val.documentDtos.map((obj:any)=> {return obj.id;}):[];
         this.fileArray = val.documentDtos?val.documentDtos.map((obj:any)=> {
           return {id:obj.id,isEvent:val.isEvent,file: JSON.parse(JSON.stringify(obj).split('"fileName":').join('"name":'))} 
      }):[]
     }
  }
  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }
  filterFileAccordingIsEvent(array:any){
    let fileArray=array.filter((fileObj:any)=>fileObj.isEvent==this.plannerEventObj.isEvent)
  return fileArray
}
  
   
}
